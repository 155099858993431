html,
body,
div {
  padding: 0;
  margin: 0;
  color: black;
  background-color: white;
}

html,
body,
div,
input,
a,
table {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

body {
  height: 100%;
}

.header_title {
  background-position: 50% 50%;
  background-repeat: repeat-x;
  background-size: auto auto;
  background-color: rgb(36, 42, 117) ;
  height: 62px;
  min-width: 800px;
  padding: 10px 0;
}

#logo {
  margin-bottom: 0;
  margin-left: 20px;
  margin-right: 0;
  margin-top: 6px;
}

#logo_safenet {
  clear: right;
  float: right;
  margin: 0 5px 10px 5px;
}

#wizardForm {
  width: 600px;
  background: none repeat scroll 0 0 #f1f2f2;
  border: 0 solid #800000;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 1px 2px 2px #dcdcdc;
  float: left;
  padding: 20px 30px;
  width: 540px;
}

.dataTables_info {
  width: 40%;
  float: left;
  clear: left;
  position: relative;
  background-color: transparent;
  line-height: 25px;
}


div.box {
  height: 100px;
  padding: 10px;
  overflow: auto;
  border: 1px solid #8080ff;
  background-color: #e5e5ff;
}

table.display thead tr {
  background: url("../images/table_header_bkgd.png") repeat-x scroll 0 50%
    #e5e1de;
}

#tableaction {
  background-color: transparent;
  position: relative;
  float: left;
  line-height: 25px;
}

#wizardWrapper h4 {
  margin: 0;

  padding: 4px 0;

  color: #707070;
}

span.sbttl {
  padding-right: 0.5em;

  margin-top: 0.5em;
}

#fieldWrapper {
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 8em;
  background: none;
}

#demoNavigation {
  margin-top: 30px;

  margin-right: 0;
  text-align: right;

  padding-bottom: 1em;

  background: none;
}

#data {
  font-size: 0.7em;
  display: none;
}

.input_field_25em {
  width: 2.5em;
}

.input_field_3em {
  width: 3em;
}

.input_field_35em {
  width: 3.5em;
}

.input_field_12em {
  width: 12em;
}

label.error {
  color: red;
  font-size: 0.8em;
  margin-left: 0.5em;
}

.step span {
  display: block;

  width: 100%;

  font-weight: bold;

  font-size: 1.2em;

  padding: 0;

  background: transparent;

  color: rgb(36,42,117);
}

#data {
  overflow: auto;
}

span.sbttl {
  padding-right: 0.5em;
}

.font_normal_07em_black {
  /*background-image: -moz-linear-gradient(center top , #4070B2 50%, #78AAE2 100%);*/

  background: url("../images/popuptitle.png") repeat-x;

  color: white;

  font-family: Arial, Helvetica, sans-serif;

  font-size: 1em;

  padding-left: 20px;

  margin-bottom: 1em;
}

.step label {
  margin-bottom: 0.2em;

  font-weight: bold;

  line-height: 22px;

  color: #707070;

  float: left;

  width: 160px;
}

#status {
  color: red;
}

#wizardForm select,
#wizardForm input[type="text"],
#wizardForm input[type="password"] {
  font-size: 0.9em;

  min-width: 168px;
}

#credentials {
  clear: both;

  background: none;

  margin-bottom: 30px;
}

#credentials th {
  color: #707070;
}

#signininfo {
  position: relative;

  float: right;

  font-size: 11px;

  color: white;

  background: transparent;

  filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=1, StartColorStr='#7f000000', EndColorStr='#7f000000');

  height: 20px;

  padding: 3px 10px;

  font-weight: bold;

  cursor: pointer;
}

#pointedcursor {
  cursor: pointer;
}

#signininfo a {
  color: white;
  font-size: 11px;
}

#signininfo a:hover {
  color: rgb(93,191,212);
}

#topmenu {
  position: relative;

  float: left;

  margin-top: 34px;

  margin-left: 50px;

  color: white;

  height: 20;

  background-color: transparent;
}

#topmenu ul {
  list-style-type: none;
  display: block;
  margin-top: 0;
  padding-left: 0px;
}

#topmenu ul li {
  display: block;

  position: relative;

  float: left;
}

#topmenu ul li a {
  color: white;
  font-weight: bold;
  padding: 0.7em 1em 1em;
  display: block;
  text-decoration: none;
}

#topmenu ul li div.selected {
  position: relative;
  color: black;
  background-color: white;
  display: block;
  font-weight: bold;
  padding: 0.7em 1em 1em;
}

/* top menu */

.topmenu {
  display: block;
  padding: 1em;
}

.topmenu ul {
  list-style-type: none;
  display: block;
  margin-top: 0;
  margin-bottom: 1em;
}

.topmenu ul li {
  display: block;

  position: relative;

  padding: 0;

  margin: 0;

  width: 30%;

  float: left;

  /*background-color:green;*/
}

.topmenu ul li a {
  display: block;

  float: left;

  position: relative;

  background-color: #ea8511;

  border: 1px solid #ea8511;

  width: 200px;

  text-align: center;

  padding: 0.5em 1em 1em 0.5em;

  margin: 0 1 2em 1em;

  color: white;

  font-weight: bold;

  text-decoration: none;

  outline-style: none;

  border-radius: 5px;

  -moz-border-radius: 5px;

  -webkit-border-radius: 5px;
}

.topmenu ul li a:hover {
  border: 1px solid silver;

  color: silver;
}

.topmenu ul li div.selected {
  display: block;

  float: left;

  position: relative;

  background-color: #8cc63f;

  border: 1px solid #8cc63f;

  width: 200px;

  text-align: center;

  font-weight: bold;

  padding: 0.5em 1em 1em 0em;

  margin: 0 1 2em 1em;

  outline-style: none;

  border-radius: 5px;

  -moz-border-radius: 5px;

  -webkit-border-radius: 5px;

  -moz-box-shadow: 5px 5px 8px #888;

  -webkit-box-shadow: 5px 5px 8px #888;

  box-shadow: 5px 5px 8px #888;
}

#pageContent {
  display: block;

  position: relative;

  /*overflow-y: auto ;

    overflow-x:hidden;*/

  padding: 0 40px;

  background: #fff;

  min-height: 400px;

  min-width: 800px;
}

#footer {
  display: none;

  position: absolute;

  width: 100%;

  bottom: 0px;

  height: 20px;

  font-size: small;

  border: 1px dashed gray;

  border-left: 0;

  border-right: 0;

  background-color: silver;
}

.popupholder {
  width: 100%;

  padding-left: 0;

  background-color: transparent;
}

.popup {
  width: 30%;

  /*border:1px dotted black;*/

  margin-top: 1em;

  padding: 0;
  width: 100%;

  background-color: white;

  padding-bottom: 2em;

  width: 600px; /*box-shadow: 5px 5px 8px #888;*/

  border: 2px white solid;

  box-shadow: 0 0 2px #222222;

  border-radius: 4px 4px 4px 4px;
}

.popup h3 {
  /*background-image: -moz-linear-gradient(center top , #4070B2 50%, #78AAE2 100%);*/

  color: #6d286b;

  font-family: Arial, Helvetica, sans-serif;

  font-size: 1.2em;

  margin-bottom: 1em;

  margin-top: 0px;

  line-height: 25px;
}

.popup form table {
  width: 100%;
}
table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
}
.popup form table td {
  font-weight: bold;

  margin-right: 5px;

  line-height: 22px;

  color: #555555;
}

.popup form table td input.text {
  border: 1px #cccccc solid;

  font-weight: normal;

  color: black;

  width: 250px;

  float: right;

  font-size: 1.1em;

  padding: 3px;
}

.popup form table td.spacer {
  height: 1em;
}

.popup form table td.nav {
  padding-top: 1em;

  border-top: 1px #cccccc solid;
}

/*.popup form table td input.button {*/

.button,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  background: rgb(93, 191, 212); /* Old browsers */

  background: -moz-linear-gradient(to top, rgb(93, 191, 212) 0%, rgb(93, 191, 212) 46%); /* FF3.6+ */

  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgb(93, 191, 212)),
    color-stop(46%, rgb(93, 191, 212))
  ); /* Chrome,Safari4+ */

  background: -webkit-linear-gradient(
    to top,
    rgb(93, 191, 212) 0%,
    rgb(93, 191, 212) 46%
  ); /* Chrome10+,Safari5.1+ */

  background: -o-linear-gradient(
    to top,
    rgb(93, 191, 212) 0%,
    rgb(93, 191, 212) 46%
  ); /* Opera11.10+ */

  background: -ms-linear-gradient(to top, rgb(93, 191, 212) 0%,rgb(93, 191, 212) 46%); /* IE10+ */

  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#A578A4', endColorstr='#652864',GradientType=0 ); /* IE6-9 */

  background: linear-gradient(to top, rgb(36,42,117) 0%, rgb(36,42,117) 46%); /* W3C */

  font-weight: bold;

  font-size: 1.2em;

  padding: 3px 10px;

  margin-left: 10px;

  color: white;

  border: 0;

  cursor: pointer;

  cursor: hand;

  border-radius: 2px 2px 2px 2px;

  box-shadow: 0 0 2px #bbbbbb;
}

div.hspan_small {
  height: 3em;

  background-color: transparent;
}

div.itemslist {
  clear: left;
}

.toppad {
  padding-top: 3em;
}

div.content form {
  padding-left: 2em;
}

/*.scrollable {

overflow: scroll ;

height:200px;

width:80%;

}*/

.pagecontent {
  position: relative;
  clear: left;
}

#tablewrapper {
  /* width: 80%; */
  padding: 0;
  font-size: 12px;
  border: 2px white solid;
  box-shadow: 0 0 2px #222222;
  border-radius: 4px 4px 4px 4px;
}

#reqtable tr td {
  font-size: 12px;
}

#reqtable tr th {
  font-size: 14px;
}

.window_title {
  color: #4070b2;

  font-size: 1.6em;

  font-weight: normal;

  margin: 0;
}

#Completed {
  background-color: transparent;
}

.c-box {
  background: #f1f2f2;

  width: 340px;

  padding: 20px 30px;

  box-shadow: 1px 2px 2px #dcdcdc;

  -moz-box-shadow: 1px 2px 2px #dcdcdc;

  -webkit-box-shadow: 1px 2px 2px #dcdcdc;

  -webkit-box-shadow: 1px 2px 2px #dcdcdc;

  border-radius: 10px;

  -moz-border-radius: 10px;

  -webkit-border-radius: 10px;

  border: 0px solid #800000;

  float: left;
}

.error-message {
  color: #ff726f;

  font-weight: bold;

  margin: 0 auto 10px auto;

  width: 360px;

  background: transparent;
}

.page-title {
  font-size: 1.6em;

  font-weight: bold;

  color: rgb(93,191,212);
  /* height: auto;
  width: auto; */

  margin: 8px 0;
}

.page-description {
  font-size: 1.1em;

  font-weight: bold;

  color: #707070;

  margin-bottom: 12px;
}

form label {
  line-height: 2em;

  color: #707070;

  display: block;

  font-weight: bold;

  font-family: inherit;
}

.content {
  margin: 0 auto;

  width: 781px;
}

ol,
ul {
  list-style: none outside none;
}

.dataTables_length {
  width: 40%;
  float: left;
  background-color: transparent;
  color: white;
}
.dataTables_paginate {
  width: 44px;
  width: 50px;

  position: relative;
  clear: right;
  float: right;
  text-align: right;
  background-color: transparent;
  /*background: url("/images/ui-bg_highlight-soft_75_cccccc_1x100.png") repeat-x scroll 50% 50% #cccccc;*/
}

/* Pagination nested */
.paginate_disabled_previous,
.paginate_enabled_previous,
.paginate_disabled_next,
.paginate_enabled_next {
  height: 19px;
  width: 19px;
  margin-left: 3px;
  float: left;
}
.paginate_disabled_previous {
  background-image: url("../images/back_disabled.jpg");
}

.paginate_enabled_previous {
  background-image: url("../images/back_enabled.jpg");
}

.paginate_disabled_next {
  background-image: url("../images/forward_disabled.jpg");
}

.paginate_enabled_next {
  background-image: url("../images/forward_enabled.jpg");
}

table.display {
  margin: 0;
  clear: both;
  width: 100%;
}

#credential_label {
  color: #707070;

  font-size: 14px;

  font-weight: bold;

  margin: 10px 0 6px 0;

  background: none;
}

.step-description {
  color: #707070;

  margin-bottom: 12px;

  background: none;

  margin-top: -10px;
}

.alignRight {
  text-align: right;
}

