body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

html,
body,
div {
  padding: 0;
  margin: 0;
  color: black;
  background-color: white;
}

html,
body,
div,
input,
a,
table {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

body {
  height: 100%;
}

.header_title {
  background-position: 50% 50%;
  background-repeat: repeat-x;
  background-size: auto auto;
  background-color: rgb(36, 42, 117) ;
  height: 62px;
  min-width: 800px;
  padding: 10px 0;
}

#logo {
  margin-bottom: 0;
  margin-left: 20px;
  margin-right: 0;
  margin-top: 6px;
}

#logo_safenet {
  clear: right;
  float: right;
  margin: 0 5px 10px 5px;
}

#wizardForm {
  width: 600px;
  background: none repeat scroll 0 0 #f1f2f2;
  border: 0 solid #800000;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 1px 2px 2px #dcdcdc;
  float: left;
  padding: 20px 30px;
  width: 540px;
}

.dataTables_info {
  width: 40%;
  float: left;
  clear: left;
  position: relative;
  background-color: transparent;
  line-height: 25px;
}


div.box {
  height: 100px;
  padding: 10px;
  overflow: auto;
  border: 1px solid #8080ff;
  background-color: #e5e5ff;
}

table.display thead tr {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAZCAYAAADwkER/AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAK8AAACvABQqw0mAAAAB50RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNS4xqx9I6wAAABR0RVh0Q3JlYXRpb24gVGltZQA2LzMvMTF8rux3AAAAMUlEQVQImZWLuQ3AMACE0O0/8j0polhu01CAYNsE8KItio0SoySo8XH5goO2cr2/8QDcBCHU25EsBgAAAABJRU5ErkJggg==) repeat-x scroll 0 50%
    #e5e1de;
}

#tableaction {
  background-color: transparent;
  position: relative;
  float: left;
  line-height: 25px;
}

#wizardWrapper h4 {
  margin: 0;

  padding: 4px 0;

  color: #707070;
}

span.sbttl {
  padding-right: 0.5em;

  margin-top: 0.5em;
}

#fieldWrapper {
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 8em;
  background: none;
}

#demoNavigation {
  margin-top: 30px;

  margin-right: 0;
  text-align: right;

  padding-bottom: 1em;

  background: none;
}

#data {
  font-size: 0.7em;
  display: none;
}

.input_field_25em {
  width: 2.5em;
}

.input_field_3em {
  width: 3em;
}

.input_field_35em {
  width: 3.5em;
}

.input_field_12em {
  width: 12em;
}

label.error {
  color: red;
  font-size: 0.8em;
  margin-left: 0.5em;
}

.step span {
  display: block;

  width: 100%;

  font-weight: bold;

  font-size: 1.2em;

  padding: 0;

  background: transparent;

  color: rgb(36,42,117);
}

#data {
  overflow: auto;
}

span.sbttl {
  padding-right: 0.5em;
}

.font_normal_07em_black {
  /*background-image: -moz-linear-gradient(center top , #4070B2 50%, #78AAE2 100%);*/

  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAZCAYAAADwkER/AAAAAXNSR0IArs4c6QAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9sMDwcPCku8WE8AAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAALklEQVQI12NwKNj0n4mBgYGBXIKFhZmBiYWVhYGJlYUFxmJlgUpAWXAxJHXMDABAkQMrPQOKoQAAAABJRU5ErkJggg==) repeat-x;

  color: white;

  font-family: Arial, Helvetica, sans-serif;

  font-size: 1em;

  padding-left: 20px;

  margin-bottom: 1em;
}

.step label {
  margin-bottom: 0.2em;

  font-weight: bold;

  line-height: 22px;

  color: #707070;

  float: left;

  width: 160px;
}

#status {
  color: red;
}

#wizardForm select,
#wizardForm input[type="text"],
#wizardForm input[type="password"] {
  font-size: 0.9em;

  min-width: 168px;
}

#credentials {
  clear: both;

  background: none;

  margin-bottom: 30px;
}

#credentials th {
  color: #707070;
}

#signininfo {
  position: relative;

  float: right;

  font-size: 11px;

  color: white;

  background: transparent;

  filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=1, StartColorStr='#7f000000', EndColorStr='#7f000000');

  height: 20px;

  padding: 3px 10px;

  font-weight: bold;

  cursor: pointer;
}

#pointedcursor {
  cursor: pointer;
}

#signininfo a {
  color: white;
  font-size: 11px;
}

#signininfo a:hover {
  color: rgb(93,191,212);
}

#topmenu {
  position: relative;

  float: left;

  margin-top: 34px;

  margin-left: 50px;

  color: white;

  height: 20;

  background-color: transparent;
}

#topmenu ul {
  list-style-type: none;
  display: block;
  margin-top: 0;
  padding-left: 0px;
}

#topmenu ul li {
  display: block;

  position: relative;

  float: left;
}

#topmenu ul li a {
  color: white;
  font-weight: bold;
  padding: 0.7em 1em 1em;
  display: block;
  text-decoration: none;
}

#topmenu ul li div.selected {
  position: relative;
  color: black;
  background-color: white;
  display: block;
  font-weight: bold;
  padding: 0.7em 1em 1em;
}

/* top menu */

.topmenu {
  display: block;
  padding: 1em;
}

.topmenu ul {
  list-style-type: none;
  display: block;
  margin-top: 0;
  margin-bottom: 1em;
}

.topmenu ul li {
  display: block;

  position: relative;

  padding: 0;

  margin: 0;

  width: 30%;

  float: left;

  /*background-color:green;*/
}

.topmenu ul li a {
  display: block;

  float: left;

  position: relative;

  background-color: #ea8511;

  border: 1px solid #ea8511;

  width: 200px;

  text-align: center;

  padding: 0.5em 1em 1em 0.5em;

  margin: 0 1 2em 1em;

  color: white;

  font-weight: bold;

  text-decoration: none;

  outline-style: none;

  border-radius: 5px;

  -moz-border-radius: 5px;

  -webkit-border-radius: 5px;
}

.topmenu ul li a:hover {
  border: 1px solid silver;

  color: silver;
}

.topmenu ul li div.selected {
  display: block;

  float: left;

  position: relative;

  background-color: #8cc63f;

  border: 1px solid #8cc63f;

  width: 200px;

  text-align: center;

  font-weight: bold;

  padding: 0.5em 1em 1em 0em;

  margin: 0 1 2em 1em;

  outline-style: none;

  border-radius: 5px;

  -moz-border-radius: 5px;

  -webkit-border-radius: 5px;

  box-shadow: 5px 5px 8px #888;
}

#pageContent {
  display: block;

  position: relative;

  /*overflow-y: auto ;

    overflow-x:hidden;*/

  padding: 0 40px;

  background: #fff;

  min-height: 400px;

  min-width: 800px;
}

#footer {
  display: none;

  position: absolute;

  width: 100%;

  bottom: 0px;

  height: 20px;

  font-size: small;

  border: 1px dashed gray;

  border-left: 0;

  border-right: 0;

  background-color: silver;
}

.popupholder {
  width: 100%;

  padding-left: 0;

  background-color: transparent;
}

.popup {
  width: 30%;

  /*border:1px dotted black;*/

  margin-top: 1em;

  padding: 0;
  width: 100%;

  background-color: white;

  padding-bottom: 2em;

  width: 600px; /*box-shadow: 5px 5px 8px #888;*/

  border: 2px white solid;

  box-shadow: 0 0 2px #222222;

  border-radius: 4px 4px 4px 4px;
}

.popup h3 {
  /*background-image: -moz-linear-gradient(center top , #4070B2 50%, #78AAE2 100%);*/

  color: #6d286b;

  font-family: Arial, Helvetica, sans-serif;

  font-size: 1.2em;

  margin-bottom: 1em;

  margin-top: 0px;

  line-height: 25px;
}

.popup form table {
  width: 100%;
}
table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: 0;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
}
.popup form table td {
  font-weight: bold;

  margin-right: 5px;

  line-height: 22px;

  color: #555555;
}

.popup form table td input.text {
  border: 1px #cccccc solid;

  font-weight: normal;

  color: black;

  width: 250px;

  float: right;

  font-size: 1.1em;

  padding: 3px;
}

.popup form table td.spacer {
  height: 1em;
}

.popup form table td.nav {
  padding-top: 1em;

  border-top: 1px #cccccc solid;
}

/*.popup form table td input.button {*/

.button,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  background: rgb(93, 191, 212); /* Old browsers */ /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera11.10+ */

  background: -ms-linear-gradient(to top, rgb(93, 191, 212) 0%,rgb(93, 191, 212) 46%); /* IE10+ */

  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#A578A4', endColorstr='#652864',GradientType=0 ); /* IE6-9 */

  background: linear-gradient(to top, rgb(36,42,117) 0%, rgb(36,42,117) 46%); /* W3C */

  font-weight: bold;

  font-size: 1.2em;

  padding: 3px 10px;

  margin-left: 10px;

  color: white;

  border: 0;

  cursor: pointer;

  cursor: hand;

  border-radius: 2px 2px 2px 2px;

  box-shadow: 0 0 2px #bbbbbb;
}

div.hspan_small {
  height: 3em;

  background-color: transparent;
}

div.itemslist {
  clear: left;
}

.toppad {
  padding-top: 3em;
}

div.content form {
  padding-left: 2em;
}

/*.scrollable {

overflow: scroll ;

height:200px;

width:80%;

}*/

.pagecontent {
  position: relative;
  clear: left;
}

#tablewrapper {
  /* width: 80%; */
  padding: 0;
  font-size: 12px;
  border: 2px white solid;
  box-shadow: 0 0 2px #222222;
  border-radius: 4px 4px 4px 4px;
}

#reqtable tr td {
  font-size: 12px;
}

#reqtable tr th {
  font-size: 14px;
}

.window_title {
  color: #4070b2;

  font-size: 1.6em;

  font-weight: normal;

  margin: 0;
}

#Completed {
  background-color: transparent;
}

.c-box {
  background: #f1f2f2;

  width: 340px;

  padding: 20px 30px;

  box-shadow: 1px 2px 2px #dcdcdc;

  -moz-box-shadow: 1px 2px 2px #dcdcdc;

  -webkit-box-shadow: 1px 2px 2px #dcdcdc;

  -webkit-box-shadow: 1px 2px 2px #dcdcdc;

  border-radius: 10px;

  -moz-border-radius: 10px;

  -webkit-border-radius: 10px;

  border: 0px solid #800000;

  float: left;
}

.error-message {
  color: #ff726f;

  font-weight: bold;

  margin: 0 auto 10px auto;

  width: 360px;

  background: transparent;
}

.page-title {
  font-size: 1.6em;

  font-weight: bold;

  color: rgb(93,191,212);
  /* height: auto;
  width: auto; */

  margin: 8px 0;
}

.page-description {
  font-size: 1.1em;

  font-weight: bold;

  color: #707070;

  margin-bottom: 12px;
}

form label {
  line-height: 2em;

  color: #707070;

  display: block;

  font-weight: bold;

  font-family: inherit;
}

.content {
  margin: 0 auto;

  width: 781px;
}

ol,
ul {
  list-style: none outside none;
}

.dataTables_length {
  width: 40%;
  float: left;
  background-color: transparent;
  color: white;
}
.dataTables_paginate {
  width: 44px;
  width: 50px;

  position: relative;
  clear: right;
  float: right;
  text-align: right;
  background-color: transparent;
  /*background: url("/images/ui-bg_highlight-soft_75_cccccc_1x100.png") repeat-x scroll 50% 50% #cccccc;*/
}

/* Pagination nested */
.paginate_disabled_previous,
.paginate_enabled_previous,
.paginate_disabled_next,
.paginate_enabled_next {
  height: 19px;
  width: 19px;
  margin-left: 3px;
  float: left;
}
.paginate_disabled_previous {
  background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAgAAZABkAAD/7AARRHVja3kAAQAEAAAAZAAA/+4ADkFkb2JlAGTAAAAAAf/bAIQAAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQICAgICAgICAgICAwMDAwMDAwMDAwEBAQEBAQECAQECAgIBAgIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMD/8AAEQgAEwATAwERAAIRAQMRAf/EAHoAAQADAQAAAAAAAAAAAAAAAAYFBwgKAQEBAAAAAAAAAAAAAAAAAAAAARAAAAQDBAUHDQEAAAAAAAAAAQIEBREDBgASEwchMRQVNkFRkTIjNBZhcYHB4UJSgjNDZUYXCBEBAAICAwAAAAAAAAAAAAAAAAERITFBcZH/2gAMAwEAAhEDEQA/AOvdZWSqUoOQs+ABDQAhoD12ChaE/wBhZVZo5gVZltlxW6KuH2hUJ1VXr6ZA7rSrEsBYnQ7hUVWmKZiWVEWbPNjI0s6fNSYJyqAknApDA1/obljd4CHiHChD7ew9XX8WmxcM353UjTGctJvWXlYq32VTj3dlucin6jdqbUOCUCmJObXCe0K0kxwZ1suYYilHOxEyggwOQYAINJ0C5JZQZaZAMM1jy5bW5hboCmltyFMRMXBNdmDNMYscQ4GklAbxhHVyWBf4l7X6o8Rx63Lsfn12UWqd/wBq3mo45heNq3PDX7kdMOeySdoQdqgPHHp3Pz9PttV4He3j+1d//H3r1zo2mHy3fLYnr//Z);
}

.paginate_enabled_previous {
  background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAgAAZABkAAD/7AARRHVja3kAAQAEAAAAZAAA/+4ADkFkb2JlAGTAAAAAAf/bAIQAAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQICAgICAgICAgICAwMDAwMDAwMDAwEBAQEBAQECAQECAgIBAgIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMD/8AAEQgAEwATAwERAAIRAQMRAf/EAJAAAAIDAAAAAAAAAAAAAAAAAAcIBQYKAQABBAMAAAAAAAAAAAAAAAAAAQMFBgQHCBAAAAUCAQcHDAMAAAAAAAAAAQIDBAURBgcAITESExQIUZEyIzQVNkFhcYHB4UJSgjNDFmVGFxEAAgAFAgQEBwAAAAAAAAAAAQIAEQMEBSEGMUESB1GxIhTwYZFCghMj/9oADAMBAAIRAxEAPwDXu8vJ0k4OQq9ACmYBDMHtyIIXaweNXBvFXE+7sIsNMQY2/LssOIWlbycWuIy9sW+olIsowYR1dTUDwbu4iOXtFmTVZwq02RyuQROBSGsdfaeetNurum6t3p4Z7pbdXbTrqMlSoOkcZdNNiSZcpT1kwLmi1f2ysDWC9UvkCB5mCJ/ocltu0BT9h2VKfj3Ho6fmz5VyMjSFW4jMO7b4gMP7jwruyfu6CgbiICT91Zd0SNrybhECHTUj3y0esmnKwb4ihiO2DkqrVymNDkqBTFsO2M+Ns5mllzaWl8lM60blC9Jh+LK6sPtZWBB8RMFi4oe4omkGZJ81MiPjwMKTwf8ACow4SbquRtba8YvacjbrqPjHLBJNooJhk4hykm+aGMZVB1smphECmVSENB65g3h3Y7x4TuLsOwwmNtBj7i1vlqNbqPQP5VUZkcaOJsomwV+HpkJxD4zFVbG8etUb9gZJdXPiDIjl5Q4n7L1v3R8R16Xl3P06cubpRPTgTz+9d5uPHNNY2juemn4K56cuQYDxiEHeqD449fc/Lz+/JYXlFd6+v9q7f/H62tqc280+nV8+RCfWP//Z);
}

.paginate_disabled_next {
  background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAgAAZABkAAD/7AARRHVja3kAAQAEAAAAZAAA/+4ADkFkb2JlAGTAAAAAAf/bAIQAAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQICAgICAgICAgICAwMDAwMDAwMDAwEBAQEBAQECAQECAgIBAgIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMD/8AAEQgAEwATAwERAAIRAQMRAf/EAHoAAAICAwAAAAAAAAAAAAAAAAIFBAYHCAoBAQEAAAAAAAAAAAAAAAAAAAABEAAABAMEBAoLAAAAAAAAAAABAgMEEQUGABIUByExEwhBUZEygiM0FRY2YXGB4UJSM0NlRhcRAQACAwEAAAAAAAAAAAAAAAABESExQZH/2gAMAwEAAhEDEQA/AOpPeK3oJDu3ZW1VnBWbWoZpS1HuKYTm7KlWLeZz3D1LVkipFJ0yYOXjBN4WXuZ8RwsQFQUFBI+zA57pDBOyr3mMvs7aWQrLK6tpXVsiWApVlGR1UH8rcnJtAYT2TPkWs4kEzKmIGFs8QQXAogN2AgIgx/ocy23aAh4h2UIfbwPN1/NpsXDV7eGpGXZ0Zf1BlpNngtpZUEypJw+VIJRPhqdrKnqnVTKUwwisWS3Ola6QdG0Zlnl2IK0TS0pkb3CAzczRsiklM5ikUpQg9XSAgKEvBeAggIFHhEdNoD8S9b9UfMcedw4P167KLYnn+K7zceeYXjau54a/gjphx2STskHFQHzx7e5+Pl99qvFd6+P7V2/8fevXOTEw6N302J6//9k=);
}

.paginate_enabled_next {
  background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAgAAZABkAAD/7AARRHVja3kAAQAEAAAAZAAA/+4ADkFkb2JlAGTAAAAAAf/bAIQAAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQICAgICAgICAgICAwMDAwMDAwMDAwEBAQEBAQECAQECAgIBAgIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMD/8AAEQgAEwATAwERAAIRAQMRAf/EAI4AAAIDAQAAAAAAAAAAAAAAAAUHBAYICgEAAQUBAAAAAAAAAAAAAAAAAQADBAUGBxAAAAQDBAUHDQAAAAAAAAAAAQIDBBEFBgASBwgxEyMUFSFRkTI0FjZBYXGB4UJSgjNDZUYXEQABAwMDAgUFAQAAAAAAAAABEQIDAAQFITESBgdBYYGRMvBRsSITFP/aAAwDAQACEQMRAD8A6j8yGaWnMs+FNW4zVw3n8xpGjF6YJOWtLsm0yngNqlq2RUgk6ZMHLxgk7LL3U/TXWICoKCgkfVgc90hrbCYa7z+UixFgn+ybkGAqhLWuemgJ14oNN/Km5pWwRmV/xG/ulScIc0mGmPVJI1thJX0lrWn1bhHCstXOnMJS6UT1oS6oJK9SbTmnpoVMQMLV83brgUQNcuiAic1gcx07eHH5u3lt7seDwij7tOzh5tJFCKaKdnOFwc363o1/Q5lru0BDvDqoQ+3uPV0/Fy2qKe0rFWcigH2YDA6usIJa+bs3VXP6GAXThcqKTdpIsQ6SqWYLHMJTjs2EmVEClKJjjAoBEQtuu2ufxfSvW9h1DmRI7HWjpHuawK5xEMgYwBR83lrSSQGgknQGoV/BJcWb4IU5uAGuw1Cn0FAcD8vGEGAx0ZrR8kbt6qGWcNmFQpLLN3MwRMmUFU3SSKxEXaRjheAFinApgAxQKYI21XcvvX1P3ID7G8EMOE/pzbEI4y4EH9Tz48mlNEYW6KCXCo2PxNvYo9imZEVT66L+abHeXa/VHxHHreXc/TptxtKtVpTz/euJuPHMLxtHB4afcjyw57I0jvQQd6gPjj18H5+n22NHwqu7eP7V2/8AH3r1zo3mHy3fPZUPev/Z);
}

table.display {
  margin: 0;
  clear: both;
  width: 100%;
}

#credential_label {
  color: #707070;

  font-size: 14px;

  font-weight: bold;

  margin: 10px 0 6px 0;

  background: none;
}

.step-description {
  color: #707070;

  margin-bottom: 12px;

  background: none;

  margin-top: -10px;
}

.alignRight {
  text-align: right;
}



.ui-state-disabled, .ui-widget-content .ui-state-disabled, .ui-widget-header .ui-state-disabled { opacity: .35; filter:Alpha(Opacity=35); background-image: none; }
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default { border: 1px solid #cccccc; font-weight: normal; color: black; background-color:white;}
.ui-helper-reset { margin: 0; padding: 0; border: 0; outline: 0; line-height: 1.3; text-decoration: none; font-size: 100%; list-style: none; }
/*
 *  File:         demo_table.css
 *  CVS:          $Id$
 *  Description:  CSS descriptions for DataTables demo pages
 *  Author:       Allan Jardine
 *  Created:      Tue May 12 06:47:22 BST 2009
 *  Modified:     $Date$ by $Author$
 *  Language:     CSS
 *  Project:      DataTables
 *
 *  Copyright 2009 Allan Jardine. All Rights Reserved.
 *
 * ***************************************************************************
 * DESCRIPTION
 *
 * The styles given here are suitable for the demos that are used with the standard DataTables
 * distribution (see www.datatables.net). You will most likely wish to modify these styles to
 * meet the layout requirements of your site.
 *
 * Common issues:
 *   'full_numbers' pagination - I use an extra selector on the body tag to ensure that there is
 *     no conflict between the two pagination types. If you want to use full_numbers pagination
 *     ensure that you either have "example_alt_pagination" as a body class name, or better yet,
 *     modify that selector.
 *   Note that the path used for Images is relative. All images are by default located in
 *     ../images/ - relative to this CSS file.
 */

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * DataTables features
 */

.dataTables_wrapper {
	position: relative;
	/* min-height: 302px; */
	clear: both;
	_height: 302px;
	zoom: 1; /* Feeling sorry for IE */
}

.dataTables_processing {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 250px;
	height: 30px;
	margin-left: -125px;
	margin-top: -15px;
	padding: 14px 0 2px 0;
	border: 1px solid #ddd;
	text-align: center;
	color: #999;
	font-size: 14px;
	background-color: white;
}

.dataTables_length {
	width: 40%;
	float: left;
	background-color:transparent;
	color:white;


}
.dataTables_length  label {
    padding-left:1em;
}
.dataTables_filter {
	width: 50%;
	float: right;
	text-align: right;
	background-color:transparent;
	color:white;


}
.dataTables_filter  label {
    padding-right:1em;
}

.dataTables_info {
	width: 40%;
	float: left;
	clear:left;
	position:relative;
	background-color:transparent;
	line-height:25px;

}

.dataTables_paginate {
	width: 44px;
    width: 50px;

    position:relative;
    clear:right;
	float: right;
	text-align: right;
	background-color:transparent;
	/*background: url("/images/ui-bg_highlight-soft_75_cccccc_1x100.png") repeat-x scroll 50% 50% #cccccc;*/
}

/* Pagination nested */
.paginate_disabled_previous, .paginate_enabled_previous, .paginate_disabled_next, .paginate_enabled_next {
	height: 19px;
	width: 19px;
	margin-left: 3px;
	float: left;
}

.paginate_disabled_previous {
	background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAgAAZABkAAD/7AARRHVja3kAAQAEAAAAZAAA/+4ADkFkb2JlAGTAAAAAAf/bAIQAAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQICAgICAgICAgICAwMDAwMDAwMDAwEBAQEBAQECAQECAgIBAgIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMD/8AAEQgAEwATAwERAAIRAQMRAf/EAHoAAQADAQAAAAAAAAAAAAAAAAYFBwgKAQEBAAAAAAAAAAAAAAAAAAAAARAAAAQDBAUHDQEAAAAAAAAAAQIEBREDBgASEwchMRQVNkFRkTIjNBZhcYHB4UJSgjNDZUYXCBEBAAICAwAAAAAAAAAAAAAAAAERITFBcZH/2gAMAwEAAhEDEQA/AOvdZWSqUoOQs+ABDQAhoD12ChaE/wBhZVZo5gVZltlxW6KuH2hUJ1VXr6ZA7rSrEsBYnQ7hUVWmKZiWVEWbPNjI0s6fNSYJyqAknApDA1/obljd4CHiHChD7ew9XX8WmxcM353UjTGctJvWXlYq32VTj3dlucin6jdqbUOCUCmJObXCe0K0kxwZ1suYYilHOxEyggwOQYAINJ0C5JZQZaZAMM1jy5bW5hboCmltyFMRMXBNdmDNMYscQ4GklAbxhHVyWBf4l7X6o8Rx63Lsfn12UWqd/wBq3mo45heNq3PDX7kdMOeySdoQdqgPHHp3Pz9PttV4He3j+1d//H3r1zo2mHy3fLYnr//Z);
}

.paginate_enabled_previous {
	background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAgAAZABkAAD/7AARRHVja3kAAQAEAAAAZAAA/+4ADkFkb2JlAGTAAAAAAf/bAIQAAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQICAgICAgICAgICAwMDAwMDAwMDAwEBAQEBAQECAQECAgIBAgIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMD/8AAEQgAEwATAwERAAIRAQMRAf/EAJAAAAIDAAAAAAAAAAAAAAAAAAcIBQYKAQABBAMAAAAAAAAAAAAAAAAAAQMFBgQHCBAAAAUCAQcHDAMAAAAAAAAAAQIDBAURBgcAITESExQIUZEyIzQVNkFhcYHB4UJSgjNDFmVGFxEAAgAFAgQEBwAAAAAAAAAAAQIAEQMEBSEGMUESB1GxIhTwYZFCghMj/9oADAMBAAIRAxEAPwDXu8vJ0k4OQq9ACmYBDMHtyIIXaweNXBvFXE+7sIsNMQY2/LssOIWlbycWuIy9sW+olIsowYR1dTUDwbu4iOXtFmTVZwq02RyuQROBSGsdfaeetNurum6t3p4Z7pbdXbTrqMlSoOkcZdNNiSZcpT1kwLmi1f2ysDWC9UvkCB5mCJ/ocltu0BT9h2VKfj3Ho6fmz5VyMjSFW4jMO7b4gMP7jwruyfu6CgbiICT91Zd0SNrybhECHTUj3y0esmnKwb4ihiO2DkqrVymNDkqBTFsO2M+Ns5mllzaWl8lM60blC9Jh+LK6sPtZWBB8RMFi4oe4omkGZJ81MiPjwMKTwf8ACow4SbquRtba8YvacjbrqPjHLBJNooJhk4hykm+aGMZVB1smphECmVSENB65g3h3Y7x4TuLsOwwmNtBj7i1vlqNbqPQP5VUZkcaOJsomwV+HpkJxD4zFVbG8etUb9gZJdXPiDIjl5Q4n7L1v3R8R16Xl3P06cubpRPTgTz+9d5uPHNNY2juemn4K56cuQYDxiEHeqD449fc/Lz+/JYXlFd6+v9q7f/H62tqc280+nV8+RCfWP//Z);
}

.paginate_disabled_next {
	background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAgAAZABkAAD/7AARRHVja3kAAQAEAAAAZAAA/+4ADkFkb2JlAGTAAAAAAf/bAIQAAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQICAgICAgICAgICAwMDAwMDAwMDAwEBAQEBAQECAQECAgIBAgIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMD/8AAEQgAEwATAwERAAIRAQMRAf/EAHoAAAICAwAAAAAAAAAAAAAAAAIFBAYHCAoBAQEAAAAAAAAAAAAAAAAAAAABEAAABAMEBAoLAAAAAAAAAAABAgMEEQUGABIUByExEwhBUZEygiM0FRY2YXGB4UJSM0NlRhcRAQACAwEAAAAAAAAAAAAAAAABESExQZH/2gAMAwEAAhEDEQA/AOpPeK3oJDu3ZW1VnBWbWoZpS1HuKYTm7KlWLeZz3D1LVkipFJ0yYOXjBN4WXuZ8RwsQFQUFBI+zA57pDBOyr3mMvs7aWQrLK6tpXVsiWApVlGR1UH8rcnJtAYT2TPkWs4kEzKmIGFs8QQXAogN2AgIgx/ocy23aAh4h2UIfbwPN1/NpsXDV7eGpGXZ0Zf1BlpNngtpZUEypJw+VIJRPhqdrKnqnVTKUwwisWS3Ola6QdG0Zlnl2IK0TS0pkb3CAzczRsiklM5ikUpQg9XSAgKEvBeAggIFHhEdNoD8S9b9UfMcedw4P167KLYnn+K7zceeYXjau54a/gjphx2STskHFQHzx7e5+Pl99qvFd6+P7V2/8fevXOTEw6N302J6//9k=);
}

.paginate_enabled_next {
	background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAgAAZABkAAD/7AARRHVja3kAAQAEAAAAZAAA/+4ADkFkb2JlAGTAAAAAAf/bAIQAAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQICAgICAgICAgICAwMDAwMDAwMDAwEBAQEBAQECAQECAgIBAgIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMD/8AAEQgAEwATAwERAAIRAQMRAf/EAI4AAAIDAQAAAAAAAAAAAAAAAAUHBAYICgEAAQUBAAAAAAAAAAAAAAAAAQADBAUGBxAAAAQDBAUHDQAAAAAAAAAAAQIDBBEFBgASBwgxEyMUFSFRkTI0FjZBYXGB4UJSgjNDZUYXEQABAwMDAgUFAQAAAAAAAAABEQIDAAQFITESBgdBYYGRMvBRsSITFP/aAAwDAQACEQMRAD8A6j8yGaWnMs+FNW4zVw3n8xpGjF6YJOWtLsm0yngNqlq2RUgk6ZMHLxgk7LL3U/TXWICoKCgkfVgc90hrbCYa7z+UixFgn+ybkGAqhLWuemgJ14oNN/Km5pWwRmV/xG/ulScIc0mGmPVJI1thJX0lrWn1bhHCstXOnMJS6UT1oS6oJK9SbTmnpoVMQMLV83brgUQNcuiAic1gcx07eHH5u3lt7seDwij7tOzh5tJFCKaKdnOFwc363o1/Q5lru0BDvDqoQ+3uPV0/Fy2qKe0rFWcigH2YDA6usIJa+bs3VXP6GAXThcqKTdpIsQ6SqWYLHMJTjs2EmVEClKJjjAoBEQtuu2ufxfSvW9h1DmRI7HWjpHuawK5xEMgYwBR83lrSSQGgknQGoV/BJcWb4IU5uAGuw1Cn0FAcD8vGEGAx0ZrR8kbt6qGWcNmFQpLLN3MwRMmUFU3SSKxEXaRjheAFinApgAxQKYI21XcvvX1P3ID7G8EMOE/pzbEI4y4EH9Tz48mlNEYW6KCXCo2PxNvYo9imZEVT66L+abHeXa/VHxHHreXc/TptxtKtVpTz/euJuPHMLxtHB4afcjyw57I0jvQQd6gPjj18H5+n22NHwqu7eP7V2/8AH3r1zo3mHy3fPZUPev/Z);
}



/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * DataTables display
 */
table.display {
	margin: 0 ;
	clear: both;
	width: 100%;
	
	/* Note Firefox 3.5 and before have a bug with border-collapse
	 * ( https://bugzilla.mozilla.org/show%5Fbug.cgi?id=155955 ) 
	 * border-spacing: 0; is one possible option. Conditional-css.com is
	 * useful for this kind of thing
	 *
	 * Further note IE 6/7 has problems when calculating widths with border width.
	 * It subtracts one px relative to the other browsers from the first column, and
	 * adds one to the end...
	 *
	 * If you want that effect I'd suggest setting a border-top/left on th/td's and 
	 * then filling in the gaps with other borders.
	 */
}

table.display thead th {
	padding: 3px 18px 3px 10px;
	/*border-bottom: 1px solid black;*/
	font-weight: bold;
	cursor: pointer;
	cursor: hand;
}

table.display tfoot th {
	padding: 3px 18px 3px 10px;
	border-top: 1px solid black;
	font-weight: bold;
}

table.display tr.heading2 td {
	border-bottom: 1px solid #aaa;
}

table.display td {
	padding: 3px 10px;
	white-space:nowrap;
}

table.display td.center {
	text-align: center;
}



/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * DataTables sorting
 */

.sorting_asc {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAFo9M/3AAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAABClJREFUOBEBHgTh+wAAAAAAAAAAAAAAAAAAjIqM/wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACMioz/AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACMioz/AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIyKjP8AAAAAjIqM/wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACMioz/AAAAAAAAAAB0dnQBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACMioz/jIqM/wAAAAAAAAAAAAAAAIyKjP+Mioz/AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABeXRurcbNL5gAAAABJRU5ErkJggg==) no-repeat center right;
}

.sorting_desc {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAFo9M/3AAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAABClJREFUOBEBHgTh+wAAAAAAAAAAAAAAAAAAjIqM/wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACMioz/AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIyKjP8AAAAAAAAAAIyKjP8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIyKjP8AAAAAjIqM/wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACMioz/AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIyKjP+Mioz/AAAAAIyKjP+Mioz/AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACMioz/AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADsER+Nl4jWjgAAAABJRU5ErkJggg==) no-repeat center right;
}

.sorting {
    background-color: #555555;

	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9sMCBYoCP9EevgAAAA7SURBVDjLY2AY3qCnq+c/ITWMxGguKSvBqY4Jn2aYRmJcgqIZXQM2sUEe8hR7AVkDIc0URyPFCWkYAABxnTN0oHrxAAAAAABJRU5ErkJggg==) no-repeat center right;
    /*background: url("/images/ui-icons_888888_256x240.png") ;
    background-position: -128px 0;
    
    background-repeat: no-repeat;

    background-size:16px 16px;*/

}

.sorting_asc_disabled {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAMAAABFjsb+AAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAAnUExURf7+/uTk5O/v7/j4+PPz8+rq6vv7+/39/eDg4N7e3uPj49zc3P///5Oh5+8AAAANdFJOU////////////////wA96CKGAAAAQklEQVR42tTOuxIAEAxE0UTEa/3/9xo0hNG71c6pluoZvRaVeJiDWgsZEGMKgHcT9PxmPCzRYh4zZ17dPv9jTYABAN8tEESKzzNSAAAAAElFTkSuQmCC) no-repeat center right;
}

.sorting_desc_disabled {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAMAAABFjsb+AAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAAnUExURf7+/uTk5O/v7/j4+PPz8+rq6vv7+/39/eDg4N7e3uPj49zc3P///5Oh5+8AAAANdFJOU////////////////wA96CKGAAAAQUlEQVR42mLgwQQMQ1aMiRsCWJDEGDjAQowoelnAYsyo5jEChVjR7GDm5uZkQ7eXlZsJwy3sXAyY7mPA62aAAAMASJgQRHJpqXAAAAAASUVORK5CYII=) no-repeat center right;
}





/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * DataTables row classes
 */
table.display tr.odd.gradeA {
	background-color: #ffffff;
}

table.display tr.even.gradeA {
	background-color: #f1f1ec;
}

table.display tr.odd.gradeC {
	background-color: #ddddff;
}

table.display tr.even.gradeC {
	background-color: #eeeeff;
}

table.display tr.odd.gradeX {
	background-color: #ffdddd;
}

table.display tr.even.gradeX {
	background-color: #ffeeee;
}

table.display tr.odd.gradeU {
	background-color: #ddd;
}

table.display tr.even.gradeU {
	background-color: #eee;
}

table.display tr:nth-child(even) {
	background-color: #FFFFFF;
}

table.display tr:nth-child(odd) {
	background-color: #f1f1ec;
}




/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Misc
 */
.dataTables_scroll {
	clear: both;
}

.dataTables_scrollBody {
	*margin-top: -1px;
}

.top, .bottom {
	padding: 15px;
	background-color: #F5F5F5;
	border: 1px solid #CCCCCC;
}

.top .dataTables_info {
	float: none;
}

.clear {
	clear: both;
}

.dataTables_empty {
	text-align: center;
	height:10em;
}

tfoot input {
	margin: 0.5em 0;
	width: 100%;
	color: #444;
}

tfoot input.search_init {
	color: #999;
}

td.group {
	background-color: #d1cfd0;
	border-bottom: 2px solid #A19B9E;
	border-top: 2px solid #A19B9E;
}

td.details {
	background-color: #d1cfd0;
	border: 2px solid #A19B9E;
}


.example_alt_pagination div.dataTables_info {
	width: 40%;

}

.paging_full_numbers {
	width: 400px;
	height: 22px;
	line-height: 22px;
}

.paging_full_numbers span.paginate_button,
 	.paging_full_numbers span.paginate_active {
	border: 1px solid #aaa;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	padding: 2px 5px;
	margin: 0 3px;
	cursor: pointer;
	*cursor: hand;
}

.paging_full_numbers span.paginate_button {
	background-color: #ddd;
}

.paging_full_numbers span.paginate_button:hover {
	background-color: #ccc;
}

.paging_full_numbers span.paginate_active {
	background-color: #99B3FF;
}

table.display tr.even.row_selected td {
	background-color: #B0BED9;
}

table.display tr.odd.row_selected td {
	background-color: #9FAFD1;
}


/*
 * Sorting classes for columns
 */
/* For the standard odd/even */
tr.odd td.sorting_1 {
	background-color: #F1F1EC;
}

tr.odd td.sorting_2 {
	background-color: #DADCFF;
}

tr.odd td.sorting_3 {
	background-color: #E0E2FF;
}

tr.even td.sorting_1 {
	background-color: #FFFFFF;
}

tr.even td.sorting_2 {
	background-color: #F2F3FF;
}

tr.even td.sorting_3 {
	background-color: #F9F9FF;
}


/* For the Conditional-CSS grading rows */
/*
 	Colour calculations (based off the main row colours)
  Level 1:
		dd > c4
		ee > d5
	Level 2:
	  dd > d1
	  ee > e2
 */
tr.odd.gradeA td.sorting_1 {
	background-color: #c4ffc4;
}

tr.odd.gradeA td.sorting_2 {
	background-color: #d1ffd1;
}

tr.odd.gradeA td.sorting_3 {
	background-color: #d1ffd1;
}

tr.even.gradeA td.sorting_1 {
	background-color: #d5ffd5;
}

tr.even.gradeA td.sorting_2 {
	background-color: #e2ffe2;
}

tr.even.gradeA td.sorting_3 {
	background-color: #e2ffe2;
}

tr.odd.gradeC td.sorting_1 {
	background-color: #c4c4ff;
}

tr.odd.gradeC td.sorting_2 {
	background-color: #d1d1ff;
}

tr.odd.gradeC td.sorting_3 {
	background-color: #d1d1ff;
}

tr.even.gradeC td.sorting_1 {
	background-color: #d5d5ff;
}

tr.even.gradeC td.sorting_2 {
	background-color: #e2e2ff;
}

tr.even.gradeC td.sorting_3 {
	background-color: #e2e2ff;
}

tr.odd.gradeX td.sorting_1 {
	background-color: #ffc4c4;
}

tr.odd.gradeX td.sorting_2 {
	background-color: #ffd1d1;
}

tr.odd.gradeX td.sorting_3 {
	background-color: #ffd1d1;
}

tr.even.gradeX td.sorting_1 {
	background-color: #ffd5d5;
}

tr.even.gradeX td.sorting_2 {
	background-color: #ffe2e2;
}

tr.even.gradeX td.sorting_3 {
	background-color: #ffe2e2;
}

tr.odd.gradeU td.sorting_1 {
	background-color: #c4c4c4;
}

tr.odd.gradeU td.sorting_2 {
	background-color: #d1d1d1;
}

tr.odd.gradeU td.sorting_3 {
	background-color: #d1d1d1;
}

tr.even.gradeU td.sorting_1 {
	background-color: #d5d5d5;
}

tr.even.gradeU td.sorting_2 {
	background-color: #e2e2e2;
}

tr.even.gradeU td.sorting_3 {
	background-color: #e2e2e2;
}


/*
 * Row highlighting example
 */
.ex_highlight #example tbody tr.even:hover, #example tbody tr.even td.highlighted {
	background-color: #ECFFB3;
}

.ex_highlight #example tbody tr.odd:hover, #example tbody tr.odd td.highlighted {
	background-color: #E6FF99;
}

.ex_highlight_row #example tr.even:hover {
	background-color: #ECFFB3;
}

.ex_highlight_row #example tr.even:hover td.sorting_1 {
	background-color: #DDFF75;
}

.ex_highlight_row #example tr.even:hover td.sorting_2 {
	background-color: #E7FF9E;
}

.ex_highlight_row #example tr.even:hover td.sorting_3 {
	background-color: #E2FF89;
}

.ex_highlight_row #example tr.odd:hover {
	background-color: #E6FF99;
}

.ex_highlight_row #example tr.odd:hover td.sorting_1 {
	background-color: #D6FF5C;
}

.ex_highlight_row #example tr.odd:hover td.sorting_2 {
	background-color: #E0FF84;
}

.ex_highlight_row #example tr.odd:hover td.sorting_3 {
	background-color: #DBFF70;
}


/*
 * KeyTable
 */
table.KeyTable td {
	border: 3px solid transparent;
}

table.KeyTable td.focus {
	border: 3px solid #3366FF;
}

table.display tr.gradeA {
	background-color: #eeffee;
}

table.display tr.gradeC {
	background-color: #ddddff;
}

table.display tr.gradeX {
	background-color: #ffdddd;
}

table.display tr.gradeU {
	background-color: #ddd;
}

div.box {
	height: 100px;
	padding: 10px;
	overflow: auto;
	border: 1px solid #8080FF;
	background-color: #E5E5FF;
}

table.display thead tr {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAZCAYAAADwkER/AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAK8AAACvABQqw0mAAAAB50RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNS4xqx9I6wAAABR0RVh0Q3JlYXRpb24gVGltZQA2LzMvMTF8rux3AAAAMUlEQVQImZWLuQ3AMACE0O0/8j0polhu01CAYNsE8KItio0SoySo8XH5goO2cr2/8QDcBCHU25EsBgAAAABJRU5ErkJggg==) repeat-x scroll 0 50% #e5e1de;
}

.dataTables_wrapper div.topbar {
	border-radius: 0px;
	border: medium none;
	background-color: rgb(36,42,117); /* FF3.6+ */
	background: -ms-linear-gradient(to top, rgb(36,42,117) 0%, rgb(36,42,117)46%); /* IE10+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#A578A4', endColorstr='#652864',GradientType=0 ); /* IE6-9 */
	background: linear-gradient(to top, rgb(36,42,117) 0%, rgb(36,42,117) 46%); /* W3C */
	color: #ffffff;
	height: 24px;
	padding: 3px 0 1px;
  }
  

.dataTables_wrapper div.bottombar {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABkCAYAAABHLFpgAAAALElEQVQYlWN49OjRfyYGBgaGIUT8//8fSqBx0Yh///4RL8vAwAAVQ2MNOwIAl6g6KkOJwk8AAAAASUVORK5CYII=);
    background-origin: padding-box;
    background-position: 50% 50%;
    background-repeat: repeat-x;
    height:30px;
    padding:0;
    padding-top:5px;
	background: linear-gradient(to top, rgb(36,42,117) 0%, rgb(36,42,117) 46%); /* W3C */
}
#tableaction{
    background-color:transparent;
    position:relative;
    float:left;
    line-height:25px;
}
